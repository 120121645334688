/* eslint-disable no-param-reassign */
import omitBy from 'lodash/omitBy';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import merge from 'lodash/merge';

const tidySeo = (seoObj = {}) => {
  const titleValue = get(seoObj, 'title.value');
  const descriptionValue = get(seoObj, 'description.value');

  delete seoObj['og:title'];
  delete seoObj['og:description'];
  delete seoObj['twitter:title'];
  delete seoObj['twitter:description'];

  const out = {
    'og:title': {
      name: 'og:title',
      value: titleValue
    },
    'og:description': {
      name: 'og:description',
      value: descriptionValue
    },
    'twitter:title': {
      name: 'twitter:title',
      value: titleValue
    },
    'twitter:description': {
      name: 'twitter:description',
      value: descriptionValue
    }
  };

  merge(out, seoObj);

  return omitBy(out, (obj) => {
    const value = get(obj, 'value');
    return isEmpty(value) || isNil(value);
  });
};

export default tidySeo;
