import get from 'lodash/get';

const getCanonicalUrl = (pageData, lang, path) => {
  const seo = get(pageData, 'seo', {});
  if (seo.canonical?.value) {
    return seo.canonical?.value;
  }

  const langSegment = lang && lang !== 'en-US' ? `${lang.toLowerCase()}/` : '';
  return langSegment || path ? `${process.env.HOST}/${langSegment}${path}` : process.env.HOST;
};

export default getCanonicalUrl;
