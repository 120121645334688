const getCoveoSearchMeta = (pageData, lang) => {
  const coveoSearchMeta = {};

  if (lang) {
    coveoSearchMeta.lang = { name: 'lang', value: lang };
  }
  if (!pageData) return coveoSearchMeta;

  if (pageData.tags && pageData.tags.length) {
    pageData.tags.forEach((tag) => {
      coveoSearchMeta[`tag:${tag.tagName}`] = { name: `tag`, value: tag.tagValue || tag.tagName || tag._id };
    });
  }

  if (pageData.category) {
    coveoSearchMeta[`category:${pageData.category.theme}`] = {
      name: `category`,
      value: pageData.category.theme || pageData.category._id
    };
  }

  if (pageData._contentTypeId) {
    coveoSearchMeta[`type:${pageData._contentTypeId}`] = {
      name: `type`,
      value: pageData._contentTypeId
    };
  }
  return coveoSearchMeta;
};

export default getCoveoSearchMeta;
