/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { readFileSync, readdirSync } from 'fs';
import pathData from '../buildArtifacts/paths';
import Layout from '../components/Layout';
import PageLanding, { PageLandingPropTypes } from '../components/PageLanding';
import PageVideoLanding, { PageVideoLandingPropTypes } from '../components/PageVideoLanding';
import { VideoCardPropTypes } from '../components/ModuleCard/VideoCard';
import tidySeo from '../utils/tidySeo';
import getCoveoSearchMeta from '../utils/getCoveoSearchMeta';
import getSeoPageTitle from '../utils/getSeoPageTitle';
import getCanonicalUrl from '../utils/getCanonicalUrl';
import getSettings from '../buildArtifacts/settings';

const { pageLanding: paths } = pathData;
const { pageVideoLanding: videoPaths } = pathData;

export const GeneralPagePropTypes = {
  pageData: PropTypes.shape(PageLandingPropTypes).isRequired,
  seo: PropTypes.object,
  pageTitle: PropTypes.string,
  canonicalUrl: PropTypes.string.isRequired,
  settingsGlobal: PropTypes.object.isRequired
};

export const VideoLandingPagePropTypes = {
  pageData: PropTypes.shape(PageVideoLandingPropTypes),
  seo: PropTypes.object,
  pageTitle: PropTypes.string.isRequired,
  canonicalUrl: PropTypes.string.isRequired,
  settingsGlobal: PropTypes.object.isRequired,
  videos: PropTypes.arrayOf(PropTypes.shape(VideoCardPropTypes)),
  wistiaVideos: PropTypes.array,
  page: PropTypes.number,
  currentVideos: PropTypes.array
};

function GeneralPage({ pageData, seo, pageTitle, canonicalUrl, settingsGlobal }) {
  const { _id, _contentTypeId, slug, introHero, contentSections, jsonLdSchema } = pageData;

  return (
    <>
      <Layout seo={seo} showFooter pageTitle={pageTitle} canonicalUrl={canonicalUrl} settingsGlobal={settingsGlobal}>
        <PageLanding
          _id={_id}
          _contentTypeId={_contentTypeId}
          slug={slug}
          introHero={introHero}
          contentSections={contentSections}
        />
        {jsonLdSchema && !isEmpty(jsonLdSchema) && (
          // eslint-disable-next-line react/no-danger
          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdSchema) }} />
        )}
      </Layout>
    </>
  );
}

function VideoLandingPage({ pageData, seo, pageTitle, canonicalUrl, settingsGlobal, videos, page, currentVideos, currentUrl }) {
  const { _id, _contentTypeId, _as, _href, slug, introHero, contentSections, wistiaVideos } = pageData;
  const newBase=canonicalUrl.replace('/video-library', '')
  const fullUrl = constructFullUrl(newBase, currentUrl);
  function constructFullUrl(newBase, currentUrl) {
    return `${newBase}${currentUrl}`;
  }
  return (
    <>
      <Layout
        seo={seo}
        showFooter
        pageTitle={pageTitle ?? 'Video Library'}
        canonicalUrl={fullUrl}
        settingsGlobal={settingsGlobal}>
        <PageVideoLanding
          _id={_id}
          _contentTypeId={_contentTypeId}
          _as={_as}
          _href={_href}
          slug={slug}
          introHero={introHero}
          videos={videos}
          wistiaVideos={wistiaVideos}
          contentSections={contentSections}
          page={page}
          currentVideos={currentVideos}
        />

        {/* {jsonLdSchema && !isEmpty(jsonLdSchema) && (
          // eslint-disable-next-line react/no-danger
          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdSchema) }} />
        )} */}
      </Layout>
    </>
  );
}

// This function gets called at build time
export const getStaticPaths = async ({ locales }) => {
  const staticPaths = flatMap(locales, (locale) =>
    [...paths, ...videoPaths]
      .map(({ params: { slug } }) => ({ params: { slug: slug === 'home' ? [''] : [slug] } }))
      .map(({ params: { slug } }) => ({ params: { slug }, locale }))
  );

  const videoPagePaths = [];

  locales?.forEach((locale) => {
    const files = readdirSync(`./src/buildArtifacts/contentJson/${locale}/pageVideo/`);
    for (let i = 1; i <= Math.ceil((files?.length || 1) / 12); i += 1) {
      videoPagePaths.push({ params: { slug: ['video-library', 'page', i.toString()] }, locale });
    }
  });

  return {
    paths: [...staticPaths, ...videoPagePaths],
    fallback: false
  };
};

export const getVideoStaticProps = async ({ locale, name, page }) => {
  const settingsGlobal = getSettings(locale);
  const pageData = JSON.parse(
    readFileSync(`./src/buildArtifacts/contentJson/${locale}/pageVideoLanding/${name}.json`, 'utf8')
  );

  const files = readdirSync(`./src/buildArtifacts/contentJson/${locale}/pageVideo/`);

  const videos =
    files?.length &&
    files?.map((file) =>
      JSON.parse(readFileSync(`./src/buildArtifacts/contentJson/${locale}/pageVideo/${file}`, 'utf8'))
    );

  const currentVideos = uniqBy(videos, (video) => video?._id)
    .sort((a, b) => new Date(b._createdDate) - new Date(a._createdDate))
    .slice((page - 1) * 12, page * 12);

  const pageDataSeo = tidySeo(get(pageData, 'seo', {}));
  const pageCoveoSearchMeta = getCoveoSearchMeta(pageData, locale);
  const seo = { ...pageCoveoSearchMeta, ...pageDataSeo };
  seo.content_type = { name: 'content_type', value: pageData._contentTypeId };

  const pageTitle = getSeoPageTitle(seo, pageData);
  delete seo.title;

  const canonicalUrl = getCanonicalUrl(pageData, locale, name);

  const allVideos = JSON.parse(readFileSync(`./src/buildArtifacts/custom/videos.json`, 'utf8'));

  if (allVideos?.length) {
         pageData.wistiaVideos = {
         success: true,
         videos: allVideos
      };
  } else {
         pageData.wistiaVideos = {
          success: false
       };
  }

  return {
    props: {
      pageData,
      seo,
      pageTitle,
      canonicalUrl,
      settingsGlobal,
      videos,
      page,
      currentVideos
    }
  };
};

export const getStaticProps = async ({ locale, params }) => {
  const settingsGlobal = getSettings(locale);
  const { slug } = params;

  const name = slug?.length ? slug[0] : 'home';

  if (name === 'videos' || name === 'video-library') {
    return getVideoStaticProps({ locale, name, page: slug?.length === 3 ? Number(slug[2]) : 1 });
  }

  const pageData = JSON.parse(
    readFileSync(`./src/buildArtifacts/contentJson/${locale}/pageLanding/${name}.json`, 'utf8')
  );

  const canonicalUrl = getCanonicalUrl(pageData, locale, name === 'home' ? '' : name);
  const seo = tidySeo(get(pageData, 'seo', {}));
  seo.content_type = { name: 'content_type', value: pageData._contentTypeId };
  const pageTitle = getSeoPageTitle(seo, pageData);
  delete seo.title;

  return {
    props: {
      pageData,
      seo,
      pageTitle,
      canonicalUrl,
      settingsGlobal
    }
  };
};

function GenericSlugComponent(props) {
  const router = useRouter();
  const currentUrl = router.asPath; // This will give you the current URL path
  const {
    pageData: { _contentTypeId }
  } = props;
  switch (_contentTypeId) {
    case 'pageVideoLanding':
      return <VideoLandingPage {...props} currentUrl={currentUrl} />;
    default:
      return <GeneralPage {...props} />;
  }
}

GenericSlugComponent.propTypes = PropTypes.object.isRequired;
GenericSlugComponent.defaultProps = {};

VideoLandingPage.propTypes = VideoLandingPagePropTypes;
VideoLandingPage.defaultProps = {
  seo: {},
  pageData: {},
  videos: [],
  wistiaVideos: [],
  page: null,
  currentVideos: []
};

GeneralPage.propTypes = GeneralPagePropTypes;
GeneralPage.defaultProps = {
  seo: {},
  pageTitle: ''
};

export default GenericSlugComponent;
